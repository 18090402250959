<!--
 * @Description: 计划任务单质检
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:45:24
 * @LastEditTime: 2022-07-15 16:08:34
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="任务单ID"
                title="任务单ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpEnum"
                placeholder="客户名称"
                title="客户名称"
                @filterChange="corpHandleFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                virtual
                clearable
                remote
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpAttrId">
              <e6-vr-select
                v-model="searchForm.corpAttrId"
                :data="corpAtrrList"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="统计时间段"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="统计时间段"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="统计时间（始）"
                end-placeholder="统计时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getAbnormalTaskList, findCorpAttr } from "@/api";
export default {
  name: "abnormalOrder",
  data() {
    return {
      searchForm: {
        taskNumber: "", //任务单号
        expectTime: [], //要求完成时间
        pageIndex: 1,
        pageSize: 20
      },
      corpAtrrList: [], //客户属性下拉框
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "tagValue",
          display: true,
          fieldLabel: "任务单ID",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "客户名称",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户属性",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpAttrName",
          display: true,
          fieldLabel: "任务单类型",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehujiancheng",
          display: true,
          fieldLabel: "要求完成时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "projectName",
          display: true,
          fieldLabel: "任务单创建人",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "任务单创建时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "任务单完成时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "unassigned",
          display: true,
          fieldLabel: "任务单完成时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nunassigned",
          display: true,
          fieldLabel: "调度任务ID",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAssig",
          display: true,
          fieldLabel: "所属大区",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAccept",
          display: true,
          fieldLabel: "所属片区",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitWork",
          display: true,
          fieldLabel: "作业城市",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workIng",
          display: true,
          fieldLabel: "作业地址",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitVerify",
          display: true,
          fieldLabel: "调度任务预约时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "normalFinished",
          display: true,
          fieldLabel: "任务项总数",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "已派工程组长任务数",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "已派工程师任务数",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "待作业任务项",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "作业中任务项",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "已完成任务项",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "是否已安排",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "工程师到达时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "调度任务完成状态",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "调度任务完成时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "改约人",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "改约次数",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "改约原因",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "改约时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "完成评价",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [listPage, base, listPageReszie],
  computed: {},
  watch: {
    // 期望解决时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expecttimeStart = val[0] || "";
        this.searchForm.expecttimeend = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findCorpAttr({ id: null })];
        let [corpAttrRes] = await Promise.all(promiseList);
        //获取客户属性
        this.corpAtrrList = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.corpDownList(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        let res = await getAbnormalTaskList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
